import { AuthContext } from "components/authProvider/authProvider";
import { getAuth, signOut } from "firebase/auth";
import { DocumentData, getFirestore } from "firebase/firestore";
import React, { useState, useEffect, useContext } from "react";
import { Navigate } from "react-router-dom";
import styles from "./eligibilityLook.module.css";
import { app, host } from "../../config";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";
import CustomAlert from "components/alert/CustomAlert";
import { v4 as uuidv4 } from "uuid";

type Member = {
  GROUPNUMBER: string;
  GroupEffDate: string;
  member_id: string;
  SSN: string;
  MemberEffDate: string;
  FirstName: string;
  LastName: string;
  MI: string;
  DOB: string;
  MemberTermDate: string;
  carrier: string;
  uid?: string; // Add uid property here
};

const auth = getAuth();
const db = getFirestore(app);

const MemberTable: React.FC = () => {
  const [members, setMembers] = useState<Member[]>([]);
  const [lastName, setLastName] = useState("");
  const [dob, setDob] = useState("");
  const [ssn, setSsn] = useState("");
  const [docData, setDocData] = useState<DocumentData | null>(null);

  const [isLoading, setIsLoading] = useState(false);

  const [isAlertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string>("");

  const openAlert = () => {
    setAlertOpen(true);
  };

  const closeAlert = () => {
    setAlertOpen(false);
  };

  const authContext = useContext(AuthContext);
  if (!authContext) {
    throw new Error("AuthContext is undefined");
  }
  const { currentUser, selectedGroup, role } = authContext;

  var tk: string;
  useEffect(() => {
    if (role === "broker" || role === "employer") {
      setIsLoading(true);
      currentUser
        ?.getIdToken(false)
        .then((idToken: string) => {
          tk = idToken;
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [selectedGroup]);

  currentUser
    ?.getIdToken(false)
    .then((idToken: string) => {
      tk = idToken;
    })
    .catch((error: any) => {
      console.log(error);
    });

  const fetchMembers = () => {
    var MemberBody = {
      lastName: lastName,
      dob: dob,
      ssn: ssn,
    };

    // console.log(tk);

    console.log("trigger");
    fetch(`${host}/api/enrollments/vba/eligibilityLookUP`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${tk}`,
      },
      body: JSON.stringify(MemberBody),
    })
      .then((response: Response) => {
        response.json().then((res) => {
          setIsLoading(false);
          setAlertMessage(`The Members Have Been queryd`);

          console.log("this is active");
          console.log(res);
          if (response.status === 200) {
            const uniqueMembers = getUniqueMembers(res);
            setMembers(uniqueMembers);
          } else {
            setAlertMessage("No data found for these search criteria.");
            setMembers([]);
            setAlertOpen(true);
          }
        });

        console.log("this is success");
        console.log(response);
      })
      .catch((error: any) => {
        console.log(error);
        setIsLoading(false);
        setAlertMessage(`There Was An Error `);
        console.log("this is fail!");
      })
      .finally(() => {
        //handleChange();
        setIsLoading(false);
      });
  };

  const getUniqueMembers = (members: Member[]) => {
    const uniqueMembers = members.reduce((acc: Member[], current: Member) => {
      const x = acc.find((item) => item.member_id === current.member_id && item.carrier === current.carrier);
      if (!x) {
        acc.push({ ...current, uid: uuidv4() });
      }
      return acc;
    }, []);
    return uniqueMembers;
  };

  const fetchMembers2 = () => {
    setIsLoading(true);
    let params = new URLSearchParams();
    params.append("lastName", lastName);
    params.append("dob", dob);
    params.append("ssn", ssn);
    const url = `${host}/api/upload/listMembers?${params}`;
    fetch(url, {
      headers: {
        authorization: `Bearer ${tk}`,
      },
    })
      .then((response: Response) => {
        if (response.status === 200) {
          setIsLoading(false);
          response.json().then((res) => {
            console.log(res.data.flat());
            setMembers(res.data.flat());
          });
        } else {
          setAlertMessage("No data found for these search criteria.");
          setMembers([]);
          setAlertOpen(true);
        }
      })
      .catch((err) => console.error("Error fetching members: ", err))
      .finally(() => setIsLoading(false));
  };

  if (docData?.Suspended == true) {
    signOut(auth)
      .then(() => {
        console.log("Signed out");
        return <Navigate to="/login" />;
      })
      .catch((error) => {
        console.error("Error signing out", error);
      });
  }

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  if (role !== "admin" && role !== "broker" && role !== "employer" && role !== "internal_ugp") {
    return <Navigate to="/basePage" />;
  }

  // Function to handle downloading member data
  const downloadMemberData = (member: Member) => {
    // Logic to download member data can be added here
  };

  if (isLoading) {
    return (
      <div className="loader-container">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className={styles["myProj-body"]}>
      <h1 className={styles["myProj-h1"]}>Eligibility Data:</h1>

      <CustomAlert message={alertMessage} isOpen={isAlertOpen} onClose={closeAlert} />

      <div className={styles["myProj-search-container"]}>
        <input
          className={styles["myProj-search-input"]}
          type="text"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          placeholder="Enter Last Name"
        />
        <Tooltip title="Please enter the date of birth in the format YYYYMMDD">
          <input
            className={styles["myProj-search-input"]}
            type="text"
            value={dob}
            onChange={(e) => setDob(e.target.value)}
            placeholder="Enter Date of Birth (YYYYMMDD)"
          />
        </Tooltip>
        <Tooltip title="SSN is Optional">
          <input
            className={styles["myProj-search-input"]}
            type="text"
            value={ssn}
            onChange={(e) => setSsn(e.target.value)}
            placeholder="Enter SSN"
          />
        </Tooltip>
        <button
          className={styles["myProj-button"]}
          onClick={() => {
            fetchMembers();
          }}
        >
          Search
        </button>
      </div>

      <table className={styles["myProj-table"]}>
        <thead>
          <tr>
            <th className={styles["myProj-th"]}>Group Number</th>
            <th className={styles["myProj-th"]}>Group Eff Date</th>
            <th className={styles["myProj-th"]}>Member ID</th>
            <th className={styles["myProj-th"]}>SSN</th>
            <th className={styles["myProj-th"]}>Member Eff Date</th>
            <th className={styles["myProj-th"]}>First Name</th>
            <th className={styles["myProj-th"]}>Last Name</th>
            <th className={styles["myProj-th"]}>MI</th>
            <th className={styles["myProj-th"]}>DOB</th>
            <th className={styles["myProj-th"]}>Member Term Date</th>
            <th className={styles["myProj-th"]}>Carrier</th>
            <th className={styles["myProj-th"]}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {members.map((member) => (
            <tr key={member.uid} className={styles["myProj-tr"]}>
              <td className={styles["myProj-td"]}>{member.GROUPNUMBER}</td>
              <td className={styles["myProj-td"]}>{member.GroupEffDate}</td>
              <td className={styles["myProj-td"]}>{member.member_id}</td>
              <td className={styles["myProj-td"]}>{member.SSN}</td>
              <td className={styles["myProj-td"]}>{member.MemberEffDate}</td>
              <td className={styles["myProj-td"]}>{member.FirstName}</td>
              <td className={styles["myProj-td"]}>{member.LastName}</td>
              <td className={styles["myProj-td"]}>{member.MI}</td>
              <td className={styles["myProj-td"]}>{member.DOB}</td>
              <td className={styles["myProj-td"]}>{member.MemberTermDate}</td>
              <td className={styles["myProj-td"]}>{member.carrier}</td>
              <td className={styles["myProj-td"]}>
                {/* <button className={styles["myProj-button"]} onClick={() => downloadMemberData(member)}>
                  Download
                </button> */}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default MemberTable;
